import { fromJS } from 'immutable'
import * as actions from './actions'

const defaultState = fromJS({
  installmentList: [],
  curPeriod: '',
  orderStatus: null,
  orderDetail: {},
  callbackUrl: '',
})

export default (state = defaultState, action) => {
  switch (action.type) {
    // 设置分期列表
    case actions.SET_INSTALLMENT_LIST:
      return state.set('installmentList', action.installmentList)

    case actions.SET_CUR_PERIOD:
      return state.set('curPeriod', action.curPeriod)

    case actions.SET_ORDER_STATUS:
      return state.set('orderStatus', action.orderStatus)

    case actions.SET_ORDER_DETAIL:
      return state.set('orderDetail', action.orderDetail)

    case actions.SET_CALLBACK_URL:
      return state.set('callbackUrl', action.callbackUrl)

    default:
      return state
  }
}
