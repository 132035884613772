import { fromJS } from 'immutable'
import { actions } from './index'

const defaultState = fromJS({
  quota: {},
  paymentStatus: 'pending', //支付状态
  billDate: '25', //账单日
  pointsInfo: {
    // 可领取积分信息
    pointsNum: 0,
    isShow: -1,
  },
})

export default (state = defaultState, action) => {
  switch (action.type) {
    //添加额度信息
    case actions.ADD_QUOTA_INFO:
      return state.merge({
        quota: fromJS(action.quota),
        price: action.price,
        period: action.curPeriod,
        callbackPageUrl: action.callbackPageUrl,
        couponVO: action.couponVO,
        merchantCouponVO: action.merchantCouponVO,
        originAmount: action.originAmount,
      })

    //设置账单日
    case actions.SET_BILL_DATE:
      return state.set('billDate', action.billDate)

    //设置借款status
    case actions.SET_PAYMENT_STATUS:
      return state.set('paymentStatus', action.status)

    case actions.SET_PAYMENT_OK_BANNER:
      return state.set('paymentSuccessBanner', action.paymentSuccessBanner)

    // 设置可领取积分信息
    case actions.SET_POINTS_INFO:
      return state.set('pointsInfo', fromJS(action.pointsInfo))

    default:
      return state
  }
}
