import useEvent from '@/hooks/useEvent'
import { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { urlQuery } from '~/common/urlQuery'

export function useRouterQuery<T = AnyRecord>() {
  const history = useHistory()

  const query = useMemo(() => {
    return urlQuery.parse<T>(history.location.search)
  }, [history.location.search])

  const updateQuery = useEvent((nextQuery: AnyRecord & T) => {
    history.push({
      pathname: history.location.pathname,
      search: urlQuery.stringify(nextQuery),
    })
  })

  return {
    query,
    updateQuery,
  }
}
