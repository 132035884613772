import { useEffect, useMemo, useRef, useState } from 'react'
import BizTracker from '~/common/tracker/bizTracker'
import Logger from '~/config/logger'
import loggerConfig from './logger.config'
import { helpers, withBasic } from '@common'
import { actionCreator } from '~/views/paymentResult/store'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Translate } from '~/config/translate'
import { addQueryToUrl } from '~/config/utils'
import { bridge } from '~/common/bridge'
import { history } from 'umi'
import { useMount } from 'ahooks'
import { globalActionCreator } from '../../common/globalActions'
import { LOGIN_METHOD } from '../../common/macro'
import { getSecurityCacheData } from '@/components/Security/utils'
import withPerfCollect from '@/components/withPerfCollect'
import { useRouterQuery } from '@/hooks/useRouterQuery'
import BridgeV2 from '@akulaku/web-bridge'
import axios from 'axios'

const trackPayStatus = (
  status,
  orderId,
  errCode,
  downPayment,
  refNo,
  dispatch,
) => {
  const saLogger = Logger.getInstance()
  let failure_reason = ''
  if (status === PaymentResultStatus.pending) {
    failure_reason = '等待付款'
  } else if (status === PaymentResultStatus.fail) {
    failure_reason = '付款已被取消'
  }
  BizTracker.expose({
    cn: 1,
    ext: {
      result: failure_reason ? 'failure' : 'success',
      failure_reason,
    },
  })

  const getAndReportConfig = async () => {
    const { URL } = helpers
    const methodToken = URL.getParam('loginMethodToken')
    const { data } = await dispatch(
      globalActionCreator.globalGetReportConfig(methodToken),
    )
    const securityCacheParams = getSecurityCacheData()
    const loginType = URL.getParam('loginType') || securityCacheParams.loginType

    saLogger.onPageView({
      ...loggerConfig.page_view,
      Aku_PageStatus:
        status === PaymentResultStatus.pending ? 'paying' : status,
      ALpayOrderID: orderId,
      code: errCode,
      // eslint-disable-next-line eqeqeq
      Aku_buttonStatus: downPayment == 0 ? 1 : 2,
      refNo: refNo,
      gatewayMerchantId: data?.gatewayMerchantId,
      gatewayMerchantName: data?.gatewayMerchantName,
      Aku_channelName: LOGIN_METHOD[loginType],
      appName: data?.appName,
    })
  }
  getAndReportConfig()

  // 重复支付
  if (
    errCode === '10810110050038' ||
    status === PaymentResultStatus.reSuccess
  ) {
    saLogger.click({
      ...loggerConfig.reSuccess,
      ALpayOrderID: orderId,
      code: errCode,
    })
  }
  // 已关单
  if (errCode === '10810110050019') {
    saLogger.click({
      ...loggerConfig.order_close,
      ALpayOrderID: orderId,
    })
  }
}
/**
 * 这个是前端自己的字段
 */
export const PaymentResultStatus = {
  pending: 'pending',
  fail: 'fail',
  success: 'success',
  reSuccess: 'reSuccess',
  payingForDownPayment: 'payingForDownPayment',
  warning: 'warning',
}

export function getPaymentFailMsg(errCode) {
  const translate = Translate.getInstance().translate
  const PaymentErrorMap = {
    // 订单已取消
    10810110050019: translate('订单已取消'),
    // 有逾期账单
    10810110050053: translate(
      '订单支付失败，您有一个逾期未付的账单，请先前往akulaku付清并重试',
    ),
    // 有逾期账单
    'ORDER.0026': translate(
      '订单支付失败，您有一个逾期未付的账单，请先前往akulaku付清并重试',
    ),
    // 额度不足
    10810110050025: translate(
      '抱歉，您当前可用额度不足，请前往akulaku APP进行提额（Financial页->Naikkan Limit），之后即可进行分期购物。',
    ),
    // 支付，额度不足
    'ORDER.0014': translate(
      '抱歉，您的额度不够，无法完成此分期付款流程. 快去Akulaku提额吧！',
    ),
    // 支付，重复支付
    10810110050038: translate('你已提交支付，请勿重复支付'),
    // 分期不可用通用原因
    'ORDER.0020': translate('此订单无法使用分期付款'),
    // 订单风控被拒
    'ORDER.0024': translate('订单支付失败，您目前不符合分期付款条件'),
    // 支付网络异常或者参数错误
    'SYSTEM.0002': translate('网络异常或参数错误'),
    // 支付网络异常或者参数错误
    'FE.Unknown Error': translate('网络异常或参数错误'),
    // 支付网络异常或者参数错误
    'FE.500': translate('网络异常或参数错误'),
    // 支付网络异常或者参数错误
    'FE.-1': translate('网络异常或参数错误'),
  }
  let msg = PaymentErrorMap[errCode]
  if (!msg) {
    // 没有定义具体文案的错误情况提示默认文案
    msg = translate(
      '抱歉，您的支付已失败，请稍后重试或者咨询Akulaku客服：1500920 / cs.id@akulaku.com。',
    )
  }
  return msg
}

/**
 * 后台服务定义的字段
 */
export const PaymentResultStatusNumber = {
  // 支付中
  pending: 7,
  // 失败
  fail: 90,
  // 成功
  success: 100,
  // 已经成功了，页面根据该状态提示不同的文案 将映射成前端自己的字段 success
  reSuccess: 102,
  // 首付支付中 需要跳转到 paymentMethod 页面支付
  payingForDownPayment: 50,
  // 未支付,可以发起支付 需要跳转到orderDetail 重新支付
  didNotPay: 2,
}

export function statusNumberToDisplayStatus(payStatus) {
  const statusMap = {
    [PaymentResultStatusNumber.success]: PaymentResultStatus.success,
    [PaymentResultStatusNumber.pending]: PaymentResultStatus.pending,
    [PaymentResultStatusNumber.reSuccess]: PaymentResultStatus.reSuccess,
    [PaymentResultStatusNumber.fail]: PaymentResultStatus.fail,
    default: PaymentResultStatus.fail,
  }
  return statusMap[payStatus] || statusMap.default
}

export function usePaymentResultLogic(props) {
  const {
    appId,
    callbackPageUrl,
    dispatch,
    refNo,
    sign,
    status,
    translate,
    location,
    orderId,
    downPayment,
  } = props
  const statusText = useMemo(() => {
    const statusTextMap = {
      [PaymentResultStatus.success]: translate('支付成功'),
      [PaymentResultStatus.reSuccess]: translate('支付成功'),
      [PaymentResultStatus.pending]: translate('等待付款'),
      default: translate('支付失败'),
    }
    return statusTextMap[status] || statusTextMap.default
  }, [translate, status])
  const { query } = useRouterQuery()
  const backToMerchant = () => {
    BizTracker.click({ cn: 2 })
    Logger.getInstance().click({
      ...loggerConfig.back_to_merchant,
      Aku_PageStatus:
        status === PaymentResultStatus.pending ? 'paying' : status,
    })
    const isAlipay = query?.isAlipay === '1'

    if (callbackPageUrl) {
      //#region 在Alipay+的场景里如果是http链接需要跳转到外部浏览器打开
      // alipay+的场景返回商户不允许携带额外参数，特殊处理
      const isHttpUrl = callbackPageUrl.startsWith('http')
      if (isAlipay) {
        if (bridge.isAkulaku && isHttpUrl) {
          bridge.openBrowser(callbackPageUrl)
          return
        }
        window.location.href = callbackPageUrl
        return
      }
      // 特殊处理，安卓的场景，调用原生的方法退出app  特殊的约定callbackPageUrl
      if (callbackPageUrl === '__closeAppProcess__') {
        BridgeV2.closeAppProcess()
        return
      }

      //#endregion
      const url = addQueryToUrl(decodeURIComponent(callbackPageUrl), {
        appId,
        refNo,
        sign,
      })
      window.location.href = url
    } else if (bridge.isAkulaku) {
      // 在站内并且没有callbackPageUrl的情况下，如果是Akulaku的话，直接跳转到订单列表页
      history.push('/order-list')
    }
  }
  const backToMerchantRef = useRef()
  backToMerchantRef.current = backToMerchant

  useMount(async () => {
    const data = await dispatch(actionCreator.goGetQuotaInfo())
    trackPayStatus(
      data.status,
      orderId,
      location?.state?.errCode,
      downPayment,
      refNo,
      dispatch,
    )
    //#region for 【【存量商户维护】openpay支持自动回跳商户结果页】
    // https://www.tapd.cn/62870105/prong/stories/view/1162870105001092797
    if (data && data.payResultPageTtl >= 0) {
      setTimeout(() => {
        backToMerchantRef.current?.()
      }, data.payResultPageTtl * 1000)
    }
    //#endregion
  })

  useEffect(() => {
    BizTracker.enter({ sn: 390008 })

    return () => {
      BizTracker.leave()
    }
  }, [])

  return {
    ...props,
    statusText,
    backToMerchant,
    // 重新请求接口获取订单信息
    checkPaymentResult() {
      Logger.getInstance().click({
        ...loggerConfig.refresh,
        Aku_PageStatus: 'paying',
      })
      dispatch(actionCreator.goGetQuotaInfo())
    },
  }
}
const mapStateToProps = (state) => ({
  appId: state.getIn(['main', 'appId']),
  refNo: state.getIn(['main', 'refNo']),
  sign: state.getIn(['main', 'sign']),
  quota: state.getIn(['paymentResult', 'quota']),
  period: state.getIn(['paymentResult', 'period']),
  callbackPageUrl: state.getIn(['paymentResult', 'callbackPageUrl']),
  price: state.getIn(['paymentResult', 'price']),
  status: state.getIn(['paymentResult', 'paymentStatus']),
  couponVO: state.getIn(['paymentResult', 'couponVO']),
  merchantCouponVO: state.getIn(['paymentResult', 'merchantCouponVO']),
  originAmount: state.getIn(['paymentResult', 'originAmount']),
  // status: PaymentResultStatus.pending,
  paymentSuccessBanner: state.getIn(['paymentResult', 'paymentSuccessBanner']),
  orderId: state.getIn(['orderDetail', 'orderId']),
  notice: state.getIn(['orderDetail', 'commonInfo', 'notice']),
  downPayment: state.getIn(['orderDetail', 'downPayment']),
  pointsInfo: state.getIn(['orderDetail', 'pointsInfo']),
})

export const applyPaymentResultHoc = compose(
  withPerfCollect(),
  connect(mapStateToProps, null),
  withBasic,
)

/** 获取活动信息 */
export function usePromotionInfo() {
  const [activityInfo, setActivityInfo] = useState()
  const getActivityData = async () => {
    const {
      data: { success, data },
    } = await axios.post(
      '/capi/openpay/order/get/discount-info',
      {},
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    return success ? data : {}
  }

  useEffect(() => {
    getActivityData().then((data) => setActivityInfo(data))
  }, [])

  return {
    activityInfo,
  }
}
