export const CHANGE_CODE_STATUS = 'login/change_code_status' //改变验证码发送状态
export const CHANGE_COUNTDOWN = 'login/change_countdown' //改变countDown变量数值，用于验证码倒计时
export const SET_CODE_ERROR_TEXT = 'login/set_code_error_text' //设置验证码需哦呜信息
export const SET_PASSWORD_ERROR_TEXT = 'login/set_password_error_text' //设置密码错误信息
export const SET_PHONE_NUMBER_ERROR_TEXT = 'login/set_phone_number_error_text' //设置手机号错误信息
export const SET_CUR_PERIOD = 'login/set_cur_period' //设置当前选择的分期数
export const SET_NEED_LOGOUT_TOAST = 'login/set_need_logout_toast'
export const SET_SHOW_INSTALLMENT_PLAN = 'login/set_show_installment_plan' // 是否展示分期计划
export const SET_AD_TAG_CONTENT = 'login/set_ad_tag_content' // 设置广告标签
export const SET_INSTALLMENT_LIST = 'login/set_installment_list' // 设置分期信息
export const SET_PAY_AMOUNT = 'login/set_pay_amount' // 设置订单金额
export const SET_MERCHANT_NAME = 'login/set_merchant_name' // 设置商户名称
export const SET_CURRENT_PAGE = 'login/set_current_page' // 设置当前页面模块
export const SET_LOGIN_TYPE = 'login/set_login_type' // 设置登录类型
export const TOGGLE_SET_PASSWORD_DIALOG = 'login/toggle_set_password_dialog'
export const SET_DEFAULT_PHONE_NUMBER = 'login/set_default_phone_number' // 设置默认手机号
export const SET_CUR_VALID_PHONE_NUMBER = 'login/set_cur_valid_phone_number' // 设置用户输入有效手机号
export const TOGGLE_SECURITY_VERIFICATION = 'login/toggle_security_verification' // 切换安全组件显示
export const SET_SECURITY_VERIFICATION_CALLER =
  'login/set_security_verification_caller' // 设置安全组件由谁调起
export const SET_SECURITY_VERIFICATION_TEMP_PARAMS =
  'login/set_security_verification_temp_params' // 保存调起安全组件操作的临时参数
export const SET_OPERATION_ID = 'login/set_operation_id' // 设置operationId
export const SET_SEND_SMS_CODE = 'login/set_send_sms_code' // 设置是否发送了短信验证码
export const SET_ACCESS_TOKEN = 'login/set_access_token' // 短信登录下一步设置accessToken
export const SET_SHOW_BIND_PROTOCOL = 'login/set_show_bind_protocol' // 设置是否显示绑定协议
export const SET_CAN_MODIFY_PHONE_NUMBER = 'login/set_can_modify_phone_number' // 设置是否可以修改登录号码

/**
 * 设置协议选择
 */
export const SET_AGREEMENT_SELECTED = 'login/SET_AGREEMENT_SELECTED'
export const SET_HOME_INFO = 'login/SET_HOME_INFO'
// 这是bannerlist
export const SET_BANNER_LIST = 'login/SET_BANNER_LIST'
