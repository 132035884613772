import { combineReducers } from 'redux-immutable'
import { connectRouter } from 'connected-react-router/immutable'
import { history } from 'umi'
import mainReducer from '../views/main/store/reducer'
import loginReducer from '../views/login/store/reducer'
import applyLimitsReducer from '../views/applyLimits/store/reducer'
import orderDetailReducer from '../views/orderDetails/store/reducer'
import registerReducer from '../views/register/store/reducer'
import paymentResultReducer from '../views/paymentResult/store/reducer'
import paymentMethodReducer from '../views/paymentMethod/store/reducer'
import paymentContinueReducer from '../views/pay-continue/store/reducer'
import couponCenterReducer from '../views/coupon-center/store/reducer'
import desktopPayReducer from '../views/desktopPay/store/reducer'

const reducer = combineReducers({
  router: connectRouter(history),
  main: mainReducer,
  login: loginReducer,
  applyLimits: applyLimitsReducer,
  orderDetail: orderDetailReducer,
  register: registerReducer,
  paymentResult: paymentResultReducer,
  paymentMethod: paymentMethodReducer,
  paymentContinue: paymentContinueReducer,
  couponCenter: couponCenterReducer,
  desktopPay: desktopPayReducer,
})

export default reducer
