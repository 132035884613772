import { actions } from './index'
import { SET_POINTS_INFO } from '../../orderDetails/store/actions'
import { helpers } from '@common'
import axios from 'axios'
import { store } from '../../../store'
import { globalActionCreator } from '../../../common/globalActions'

import {
  PaymentResultStatus,
  PaymentResultStatusNumber,
  statusNumberToDisplayStatus,
} from '~/views/paymentResult/common'
import { push } from 'connected-react-router'
import { Translate } from '~/config/translate'

const __setPaymentSuccessBanner = (paymentSuccessBanner) => ({
  type: actions.SET_PAYMENT_OK_BANNER,
  paymentSuccessBanner,
})

// 设置可领取积分信息
const setPointsInfo = (pointsInfo) => ({
  type: SET_POINTS_INFO,
  pointsInfo,
})

/*
 * 获取quota信息
 * curPeriod {string} 当前选择的分期数
 * price {string} 当前商品的价格
 * callbackPageUrl {string} 返回商家按钮url
 */
export const goGetQuotaInfo = () => {
  return async (dispatch) => {
    const translate = Translate.getInstance().translate
    const state = store.getState()
    // const isWhiteList = state.getIn(['main', 'isWhiteList'])
    const appId =
      state.getIn(['main', 'appId']) || helpers.URL.getParam('appId')
    const refNo =
      state.getIn(['main', 'refNo']) || helpers.URL.getParam('refNo')
    const routerState = state.getIn(['router', 'location', 'state'])?.toJS()
    const allParam = helpers.URL.allParam()
    let { curPeriod, price, callbackPageUrl, errCode } = routerState || allParam
    let { status } = allParam

    dispatch(globalActionCreator.toggleLoading(true))
    const {
      data: { data, errMsg, success },
    } = await axios.post(
      '/capi/openpay/user/get/payment/result',
      { appId, refNo },
      { headers: { 'Content-Type': 'application/json' } },
    )
    dispatch(globalActionCreator.toggleLoading(false))

    if (success) {
      curPeriod = data ? data.period : curPeriod
      price = data ? data.amount : price
      callbackPageUrl = data ? data.callBackPageUrl : callbackPageUrl
      dispatch({
        type: actions.ADD_QUOTA_INFO,
        quota: data,
        curPeriod,
        price,
        originAmount: data.originAmount,
        callbackPageUrl,
        couponVO: data && data.couponVO,
        merchantCouponVO: data && data.merchantCouponVO,
      })
      dispatch(__setPaymentSuccessBanner(data ? data.activityBanner || {} : {}))
    } else {
      dispatch(globalActionCreator.toggleToast(true, errMsg))
    }
    if (data) {
      // reSuccess 这个状态只有下单接口才会返回，result接口不能区分是否重复支付
      // 1. 即 reSuccess 得优先使用，如果下单返回了错误码，优先使用url上的status
      // 2. status 定义优先使用接口返回值 接口挂了没返回使用url上的
      if (status !== PaymentResultStatus.reSuccess && !errCode) {
        status = statusNumberToDisplayStatus(data.payStatus)
      }
      switch (data.payStatus) {
        case PaymentResultStatusNumber.didNotPay:
          dispatch(
            push({
              pathname: './orderDetails',
              state: { curPeriod },
              search: `${window.location.search}`,
            }),
          )
          dispatch(
            globalActionCreator.toggleToast(
              true,
              translate('订单提交失败，请重新确认支付'),
            ),
          )
          break
        case PaymentResultStatusNumber.payingForDownPayment:
          dispatch(
            push({
              pathname: `./paymentMethod`,
              state: {
                bizIds: [data.bizOrderId],
                payId: data.payId,
                price,
                curPeriod,
                callbackPageUrl,
              },
              search: window.location.search,
            }),
          )
          break
      }
    }
    if (data?.payStatus === PaymentResultStatusNumber.success) {
      // 支付成功查询积分
      const {
        data: { data: pointsInfo, success: pointsSuccess },
      } = await axios.post(
        '/capi/openpay/v2/order/payment/point',
        {
          orderNumber: data.bizOrderId,
          businessType: 1, //openPay支付方式：1:线上支付 2：线下支付
          orderFrontAmount: data.originAmount,
          orderAfterAmount: data.amount,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      pointsSuccess && dispatch(setPointsInfo(pointsInfo))
    }
    dispatch(goSetPayemntStatus(status))
    return { ...data, status }
  }
}

/*
 * 设置支付状态
 * 1. 需要在orderDetail处调用，提前设置好支付结果状态
 * 2. 也会goGetQuotaInfo调用，修正支付结果状态
 */
export const goSetPayemntStatus = (status) => ({
  type: actions.SET_PAYMENT_STATUS,
  status,
})
