export const ADD_NEW_STATE_FROM_ORDER = 'orderDetail/add_new_state_from_order' //登录后将新的订单信息添加到state
export const CHOOSE_PERIOD = 'orderDetail/choose_period' //选择分期数
export const TOGGLE_BASIC_DIALOG = 'orderDetail/toggle_basic_dialog' //显示/隐藏提示框
export const TOGGLE_BASIC_POPUP = 'orderDetail/toggle_basic_popup' //显示/隐藏slide up panel
export const SAVE_ENTER_TIME = 'orderDetail/save_enter_time' //保存进入页面的时间
export const SAVE_CLICK_PAY_TIME = 'orderDetail/save_click_play_time' //保存点击下单按钮的时间
export const SET_DOWNPAYMENT_RATE = 'orderDetail/set_downpayment_rate' //设置首付比例
export const SET_FULL_PAYMENT = 'orderDetail/set_full_payment' //设置只能全款支付
export const SHOW_LOGIN_PASSWORD_DIALOG =
  'orderDetail/show_login_password_dialog' //显示登录密码弹窗
export const CHANGE_CODE_STATUS = 'orderDetail/change_code_status' //改变验证码发送状态
export const CHANGE_COUNTDOWN = 'orderDetail/change_countdown' //改变countDown变量数值，用于验证码倒计时
export const SET_CODE_ERROR_TEXT = 'orderDetail/set_code_error_text' //设置验证码错误信息
export const SET_LOGIN_PWD_ERROR_TEXT =
  'orderDetail/set_login_password_error_text' //设置验证码错误信息
export const SAVE_OPERATION_ID = 'orderDetail/save_operation_id' //保存风控操作id
export const TOGGLE_RISK_CHECK_STATUS = 'orderDetail/toggle_risk_check_status' //保存风控操作id
export const SAVE_PAGE_ID = 'orderDetail/save_page_id' //保存pageId,埋点用
export const TOGGLE_COUPONCODE_FORM = 'orderDetail/toggle_couponcode_form' //显示隐藏优惠码
export const SET_COUPON_CODE = 'orderDetail/set_coupon_code' //设置优惠码
export const SET_COUPON_ERR_TEXT = 'orderDetail/set_coupon_err_text' //设置优惠码错误信息
export const SAVE_INTEREST_FREE_PERIOD = 'orderDetail/save_interest_free_period' //保存免息时的分期数
export const ADD_NEW_PLAN_TO_STATE = 'orderDetail/add_new_plan_to_state' //保存最新的分期计划
export const TOGGLE_DOWNPAYMENT_RATE_DISPLAY =
  'orderDetail/toggle_downpayment_rate_display' //设置首付比例显示隐藏
export const DISABLE_ORDER_BUTTON = 'orderDetail/disable_order_button'
export const DP_USER_OPTIMIZE = 'orderDetail/dp_user_optimize' //有首付用户选项优化（a/b test）
export const TOGGLE_FULL_PAYMENT = 'orderDetail/toggle_full_payment' //隐藏全款选项
export const SET_ORDER_DETAIL_BANNER = 'orderDetail/set_order_detail_banner'
export const SET_CUR_COUPON_ID = 'orderDetail/set_cur_coupon_id' // 界面展示的优惠券
export const SET_PREV_COUPON_ID = 'orderDetail/set_prev_coupon_id' // 打开优惠券弹窗时保存的优惠券
export const SET_COUPON_MODAL_FLAG = 'orderDetail/set_coupon_modal_flag' // 控制优惠券弹窗显示内容
export const SET_COUPON_LIST = 'orderDetail/set_coupon_list' // 设置优惠券信息list
export const TOGGLE_SECURITY_VERIFICATION =
  'orderDetail/toggle_security_verification' // 显示/隐藏安全组件控件
export const SET_SHOW_SECURITY_VERIFICATION =
  'orderDetail/set_show_security_verification' // 控制是否安全组件
export const SET_IS_SMALL_PAY = 'orderDetail/set_is_small_pay' // 设置小额免密的值
export const SET_SHOW_SMALL_PAY_CONTROLLER =
  'orderDetail/set_show_small_pay_controller' // 是否需要显示小额免密
export const TOGGLE_QUICK_PAYMENT_INSTRUCTION_VISIBLE =
  'orderDetail/toggle_quick_payment_instruction_visible' // 显示隐藏小额免密说明弹窗（进入页面时弹窗）
export const TOGGLE_QUICK_PAYMENT_REMINDER_VISIBLE =
  'orderDetail/toggle_quick_payment_reminder_visible' // 显示隐藏小额免密提示弹窗（下单时弹窗）
export const TOGGLE_QUICK_PAYMENT_REMINDER_HAS_OPEN =
  'orderDetail/toggle_quick_payment_reminder_has_open' // 隐藏小额免密提示弹窗（下单时弹窗）是否展示过
export const SET_SHOW_QUICK_PAY_TIP_IN_PASSWORD_MODAL =
  'orderDetail/setShowQuickPayTipInPasswordModal' // 设置密码弹窗里面的小额免密提示
export const SET_SAVE_PAYMENT_ID = 'orderDetail/set_save_payment_id' // 保存支付id
// 设置还款日
export const SET_REPAYMENT_DAY = 'orderDetail/set_repayment_day'
// 设置用户是否设置了支付密码
export const SET_SUPPORT_PAYMENT_PWD =
  'orderDetail/set_support_payment_password'
// 设置是否展示支付密码校验弹窗
export const SET_PAYMENT_VERIFICATION_VISIBLE =
  'orderDetail/set_payment_verification_visible'
// 设置支付密码校验id
export const SET_PAYMENT_VERIFICATION_ID =
  'orderDetail/set_payment_verification_id'
// 设置支付密码校验流程参数和数据
export const SET_PAYMENT_VERIFICATION_DATA =
  'orderDetail/set_payment_verification_data'
// 设置common信息
export const SET_COMMON_INFO = 'orderDetail/set_common_info'
// 设置可领取积分
export const SET_POINTS_INFO = 'orderDetail/set_points_info'
// // 设置商户名称
export const SET_MERCHANT_NAME = 'orderDetail/set_merchant_name'
// 设置汇率
export const SET_EXCHANGE_RATE = 'orderDetail/set_exchange_rate'
// 设置商户优惠
export const SET_MERCHANT_COUPON = 'orderDetail/set_merchant_coupon'
